<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner">

      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        <b-img
        style="height:120px;"
        :src="appLogoImageLogin"
        alt="logo"
        />
        <b-img
        class="ml-2"
        style="height:120px;"
        :src="appLogoImageLogin2"
        alt="logo"
        />
        <h2 class="brand-text text-primary ml-1">
          Pemantauan Sembako
        </h2>
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-flex-start"
        :style="{
        'background-image': `url(${imgUrl})`, 'background-size':'cover','background-repeat':'no-repeat','background-position':'center','background-height':'100%','background-width':'100%'}"
      >
      <!-- <div :style="{backgroundImage:`url('/assets/images/pages/bgbaru.png')`}"></div>
          <b-img
            style="object-fit: cover; max-height:100%; max-width:100%;"
            fluid
            :src="imgUrl"
            alt="Login V2"
          /> -->
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center  px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
          <b-img
            style="width:220px; height:170px; text-align:center; display: block;
            margin-left: auto;
            margin-right: auto;"
            :src="appLogoImageLogin3"
            alt="logo"
          />
            <!-- Pemantauan Sembako -->
          </b-card-title>
          <b-card-text class="mb-2">
            Login
          </b-card-text>

          <!-- <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="emails"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <!-- <span>New on our platform? </span> -->
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Buat akun baru</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
          <p style="text-align:center" class="clearfix mb-0" >
          <span class="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT  © {{ new Date().getFullYear() }}
            <b-link
              class="ml-25"
              href=""
              target="_blank"
            >Binmas</b-link>
            <span class="d-none d-sm-inline-block">, All rights Reserved</span>
          </span>

          <!-- <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
            <feather-icon
              icon="HeartIcon"
              size="21"
              class="text-danger stroke-current"
            />
          </span> -->
        </p>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import axios from 'axios';
import router from '@/router'
import { $themeConfig } from '@themeConfig'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      status: '',
      password: '',
      emails: '',
      appLogoImageLogin:require('@/assets/images/pages/binmas.png'),
      appLogoImageLogin2:require('@/assets/images/pages/baharkam.png'),
      appLogoImageLogin3:require('@/assets/images/pages/logo2.png'),
      sideImg: require('@/assets/images/pages/bg4.png'),

      // validation rules
      required,
      email,
      url: localStorage.getItem('baseapi'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/bg4.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // useJwt.login({
            const email = this.emails;
            const password = this.password;
            axios.post(this.url+'user_login', {
              email,
              password,
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                  }
            })
            .then(response => {
              if (response.data.message === 'login_failed') {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
              } else {
                var userData = response.data.data
                console.log(userData)
                userData['ability'] = [{"action":"manage","subject":"all"}];
                // userData.push('"ability":[{"action":"manage","subject":"all"}]')
                localStorage.setItem('userData', JSON.stringify(userData))
                console.log(userData.hak_akses)
                this.$ability.update(userData.ability)
                
                this.$router.replace(getHomeRouteForLoggedInUser(userData.hak_akses))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Selamat Datang ${userData.nama}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `Anda berhasil login dengan hak akses ${userData.hak_akses}.`,
                      },
                    })
                    // return '/'
                  })
              }
            })
            .catch(error => {
              console.log(error)
              localStorage.removeItem('userData')
              this.$refs.loginForm.setErrors(error)
              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: error.response.data.message,
                    },
                  })
            })
        }
      })
    },
  },
}
</script>
<style scoped>
/* @media
	  only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {
      b-img{
        text-align: center;
      }
    } */
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
